import React from "react";
import MassUploadPlanUploadModal from "../../components/modals/plans/MassUploadPlanUploadModal";
import { LoadingButton } from "@mui/lab";
import CurrentClientContext from "../../providers/CurrentClientProvider";

const OtherPlanTools = () => {
    const [displayMassUploadPlanModal, setDisplayMassUploadPlanModal] = React.useState(false);
    const client = CurrentClientContext.useClient();

    if (!client.client) {
        return null;
    }

    return (
        <div>
            <h1>Other Plan Tools</h1>
            <LoadingButton
                    onClick={() => setDisplayMassUploadPlanModal(true)}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                Mass Copy Plan Tool
            </LoadingButton>
            <MassUploadPlanUploadModal
                open={displayMassUploadPlanModal}
                onClose={() => setDisplayMassUploadPlanModal(false)}
                client={client.client}
            />
        </div>
    );
};

export default OtherPlanTools;