import * as React from 'react';
import {
	Link,
	useLocation,
	useParams,
} from "react-router-dom"
import {
  Drawer,
  AppBar,
  CssBaseline,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material"
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone';
import AutoAwesomeMotionTwoToneIcon from '@mui/icons-material/AutoAwesomeMotionTwoTone';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import _ from 'underscore'
import {LibraryBooks, SupportAgent} from "@mui/icons-material";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import UserService from "../../services/UserService";
import {useRef, useState} from "react";
import Auth from '../../providers/AuthProvider'
import ClientService from "../../services/ClientService";
import TipContext from "../../providers/GlobalTip";

const cssInJs = {
	nav: {
		backgroundColor: "#422e52",
		color: 'white',
		boxSizing: 'border-box',
		mt: '50px',
		overflow:'visible',
		borderRadius: 0,
		'::-webkit-scrollbar': {
			width: 0,
		}
	},
	navItem: {
		flexDirection: 'column',
		alignItems: 'center',
		color: 'rgba(255,255,255,0.6)',
		transition: 'all 0.3s',
		fontSize: 12,
		p: 0,
		borderBottom: '1px solid rgba(255,255,255,0.1)',
		py: 1,
		':hover': {
			color: 'rgba(255,255,255,1)',
		},
		'& .active': {
			color: 'rgba(255,255,255,1)',
		},
	},
	navItemSection: {
		position: 'relative',
		'.subNav': {
			display:'none',
			position: 'absolute',
			left:79,
			top: 0,
			width: '200px',
			backgroundColor: '#422e52',
			fontSize: 12,
			'.subItem': {
				borderBottom:'1px solid rgba(255,255,255,0.1)',
			},
			'.subItem:last-child': {
				borderBottom: 'none'
			}
		},
		":hover": {
			'.subNav': {
				display:'block',
			}
		},
	},
	navItemIcon: {
		fontSize: '26px',
		mb: 0.5,
	},
}

// Component to hold report library link button for easy re-use
function ReportLibraryLink( { currentClient } ) {
	return (
		<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
			<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/report-library/${currentClient.client.Id}`}>
				<LibraryBooksIcon sx={cssInJs.navItemIcon}/>
				Report Library
			</ListItemButton>
		</ListItem>
	)
}

function NavContent(props) {
	const currentClient = CurrentClientContext.useClient()
	const auth = Auth.useAuth()
	const tipContext = TipContext.useMessage()

	React.useEffect(() => {
	}, [])

	const getZendLink = () => {
		UserService.getZendUrl().then(res => {
			if(res.url) {
				window.open(res.url, '_blank', 'location=no')
			}
		})
	}

	const clearCache = async (action) => {
		if (confirm('This action purge all cache for client. Are you sure?') === true){
			if (action === 'regenerate') {
				await ClientService.regenerateCache(currentClient.client.Id)
				tipContext.showSuccess("Cache regenerating")
			} else {
				await ClientService.clearCache(currentClient.client.Id)
				tipContext.showSuccess("Cache cleared")
			}
		}
	}

	return <>
		<List disablePadding >
			<ListItem disableGutters disablePadding >
				<ListItemButton component={Link} sx={cssInJs.navItem} to='/'>
					<HomeTwoToneIcon sx={cssInJs.navItemIcon}/>
					Home
				</ListItemButton>
			</ListItem>
			{/* TODO: Add role check as opposed to checkPermission. Currently using "role-create" since that would mean admin, but this should really be checking is Admin/Support */}
			{auth.checkPermission('full-planning') ?
				<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
					<ListItemButton component={Link} sx={cssInJs.navItem} to={`/plan/copy`}>
						<CopyAllOutlinedIcon sx={cssInJs.navItemIcon}/>
						Copy Plan
					</ListItemButton>
				</ListItem>
			: null }
			{auth.checkPermission('add-client') ?
				<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
					<ListItemButton component={Link} sx={cssInJs.navItem} to={`/client/add`}>
						<LocalHospitalIcon sx={cssInJs.navItemIcon}/>
						Add Client
					</ListItemButton>
				</ListItem>
			: null }
			{auth.checkPermission('role-create') ? 
				<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
					<ListItemButton component={Link} sx={cssInJs.navItem} to={`/`} onClick={(e) => e.preventDefault()}>
						<AutoAwesomeMotionTwoToneIcon sx={cssInJs.navItemIcon}/>
						Admin Tools
					</ListItemButton>
					<Box className="subNav">
						<ListItemButton component={Link} to={`/admin/custom-calendar-management`} className="subItem">Custom Calendars</ListItemButton>
						{auth.checkPermission('dev-tools') ?
							<ListItemButton component={Link} to={`/dev-tools`} className="subItem">
								Dev Tools
							</ListItemButton>
						: null}
						<ListItemButton component={Link} to={`/admin/flightcontrol`} className="subItem">Flight Control</ListItemButton>
					</Box>
				</ListItem>
			: null}
			{!_.isEmpty(currentClient.client) && 
				<>
					{/* <ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
						<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/plan/locationclasses/default`}>
							<TimelineOutlinedIcon sx={cssInJs.navItemIcon}/>
							Plan
						</ListItemButton>
					</ListItem> */}
					<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
						<ListItemButton className={'menu-item'} component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/kpi/${currentClient.client.Id}`}>
							<AssessmentIcon sx={cssInJs.navItemIcon}/>
							KPI
						</ListItemButton>
					</ListItem>
					<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
						<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/analytics/${currentClient.client.Id}`}>
							<AssessmentIcon sx={cssInJs.navItemIcon}/>
							Analytics
						</ListItemButton>
					</ListItem>

					{/* Conditionally lock out non admin users based on env variable */}
					{process.env.REACT_APP_REPORT_LOCKOUT_CURRENT_MONTH ?
						<>
							{auth.checkPermission('role-create') ?
								<ReportLibraryLink currentClient={currentClient} />
							: null}
						</>
					:	<ReportLibraryLink currentClient={currentClient} /> }

					<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
						<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/upload-data`}>
							<StorageTwoToneIcon sx={cssInJs.navItemIcon}/>
							Data
						</ListItemButton>
						<Box className="subNav">
							<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/upload-data`} className="subItem">Upload Data</ListItemButton>
						</Box>
					</ListItem>

					{auth.checkPermission('role-create') ?
						<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
							<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/plan/locationclasses/default`}>
								<TimelineOutlinedIcon sx={cssInJs.navItemIcon}/>
								Plan
							</ListItemButton>
							<Box className="subNav">
								<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/planninig/${currentClient.client.Id}/preview`} className="subItem">Plan Preview</ListItemButton>
								<ListItemButton component={Link} onClick={e => clearCache('regenerate')} className="subItem">Regenerate Cache</ListItemButton>
								<ListItemButton component={Link} onClick={e => clearCache('clear')} className="subItem">Clear Cache</ListItemButton>
								<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/plan/other-tools`} className="subItem">Other Plan Tools</ListItemButton>
							</Box>
						</ListItem>
					: null}
					{/* <ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
					<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/planninig/${currentClient.client.Id}/preview`}> <StorageTwoToneIcon sx={cssInJs.navItemIcon}/>Plan Preview</ListItemButton>
					</ListItem> */}
					{/* <ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
						<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/planninig/${currentClient.client.Id}/preview`}>
							<StorageTwoToneIcon sx={cssInJs.navItemIcon}/>
							Planning
						</ListItemButton>
						<Box className="subNav">
							<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/planninig/${currentClient.client.Id}/preview`} className="subItem">Plan Preview</ListItemButton>
						</Box>
					</ListItem> */}
						<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
							<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/profile`}>
								<BusinessOutlinedIcon sx={cssInJs.navItemIcon}/>
								Profile
							</ListItemButton>
							{auth.checkPermission('view-client-info') ?
								<Box className="subNav">
									<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/locations`} className="subItem">Locations</ListItemButton>
									<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/classes`} className="subItem">Classes</ListItemButton>
									<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/categories`} className="subItem">Categories</ListItemButton>
									<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/import-class`} className="subItem">Import Class</ListItemButton>
									<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/pos-mapping`} className="subItem">POS Mapping</ListItemButton>
									{auth.checkPermission('role-create') ?
										<>
											<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/master-class`} className="subItem">Master Class</ListItemButton>
											<ListItemButton component={Link} to={`/clients/${currentClient.client.ClientCode}/tags`} className="subItem">Tags</ListItemButton>
										</>
									: null}
									{auth.checkPermission('add-client') ?
										<ListItemButton component={Link} to={`/client/${currentClient.client.ClientCode}/setup`} className="subItem">Setup</ListItemButton>
									: null}
								</Box>
							: null}
						</ListItem>
					{auth.checkPermission('role-create') ?
						<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
							<ListItemButton component={Link} sx={cssInJs.navItem} to={`/clients/${currentClient.client.ClientCode}/sync`}>
								<AutoAwesomeMotionTwoToneIcon sx={cssInJs.navItemIcon}/>
								Sync
							</ListItemButton>
						</ListItem>
					: null}
					<ListItem disableGutters disablePadding sx={cssInJs.navItemSection}>
						<ListItemButton component={Box} sx={cssInJs.navItem} onClick={e => getZendLink()}>
							<SupportAgent sx={cssInJs.navItemIcon}/>
							Support
						</ListItemButton>
					</ListItem>
				</>
			}
		</List>
	</>
}

function LeftNav(props) {
	return (
		<Box component="nav" sx={{ width: { sm: props.width }, flexShrink: { sm: 0 } }}>
			<Drawer
        variant="temporary"
        open={props.navOpen}
        onClose={() => {props.onNavClose()}}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { 
          	width: props.width,
          	...cssInJs.nav,
          },
        }}
      >
        <NavContent />
      </Drawer>
      <Drawer 
        variant="permanent" 
        sx={{
          display: { xs: 'none', sm: 'block' }, 
          '& .MuiDrawer-paper': { 
          	width: props.width,
          	...cssInJs.nav,
          },
        }}
      >
        <NavContent />
      </Drawer>
		</Box>
	)
}

export default LeftNav